import { Flex, Text } from '../../Components'
import { labelBarChart } from './statisticsFunctions'

export const PercentageInfoNumber = ({ data, itemLabel, percentageStateTotal, big, overlayLabel, noTotal }) => {
  const { label, color } = itemLabel
  const percentage = percentageStateTotal?.find(item => item.name === label)?.value || 0
  const labelTotal = noTotal ? '' : `(${data?.length || 0})`
  return (
    <Flex fw row jb>
      <Text size={big ? 18 : 15} value={`${overlayLabel || labelBarChart(label)} ${labelTotal}`} color={color} />
      <Flex row jb style={{ width: 100 }}>
        <Text size={big ? 18 : 15} bold value={`${percentage} %`} color={color} />
      </Flex>
    </Flex>
  )
}
