import React from 'react'
import { Flex, PanelText } from '../../../Components'
import { Checkbox } from 'primereact/checkbox'

/**
 * Componente per la creazione di un pannello con una casella di controllo e testo associato.
 *
 * Questo componente rende un'interfaccia utente che include una casella di controllo (checkbox)
 * e due elementi di testo. Il primo elemento di testo funge da intestazione accanto alla casella
 * di controllo, mentre il secondo fornisce una descrizione aggiuntiva sotto l'intestazione.
 *
 * @param {boolean} checked - Indica se la casella di controllo è selezionata.
 * @param {function} onChange - Funzione chiamata quando lo stato della casella di controllo cambia.
 * @param {string} headerValue - Testo visualizzato come intestazione accanto alla casella di controllo.
 * @param {string} description - Descrizione testuale aggiuntiva visualizzata sotto l'intestazione.
 * @param {boolean} [isFirst=false] - Determina se applicare margine superiore al pannello.
 *
 * @author mranocchia
 */
const CheckboxPanel = ({ checked, onChange, headerValue, description, isFirst = false }) => (
  <Flex js fw style={{ marginTop: isFirst ? 0 : 30 }}>
    <Flex row js fw>
      <Checkbox
        checked={checked} onChange={e => onChange(e.checked)}
      />
      <PanelText value={headerValue} header style={{ marginLeft: 5 }} />
    </Flex>
    <Flex row js fw>
      <PanelText value={description} />
    </Flex>
  </Flex>
)

export default CheckboxPanel
