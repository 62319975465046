import React, { useState } from 'react'
import { Button, Flex } from '../../../Components'
import { FC } from '../../../Services'
import { FloatInput } from '../GigiForm/TransferField/InputForm'

export function ModalSegnalazione ({ onHide }) {
  const [reportText, setReportText] = useState('')
  const [isSending, setIsSending] = useState(false)

  const sendReport = async () => {
    if (isSending) return
    setIsSending(true)
    const currentUrl = window.location.href
    await FC.service('info').create({ action: 'sendReport', reportText, conversationUrl: currentUrl })
    window.growl.show({ severity: 'success', summary: 'Segnalazione inviata', detail: 'La segnalazione è stata inviata con successo' })
    onHide()
    setIsSending(false)
  }

  return (
    <Flex fw>
      <FloatInput
        value={[reportText, setReportText]} label='Note segnalazione' type='textarea'
      />
      <Flex fw row jb style={{ marginTop: 20 }}>
        <Button label='Annulla' onClick={onHide} style={{ marginRight: 20 }} />
        <Button label='Invia' onClick={sendReport} spinner={isSending} />
      </Flex>
    </Flex>
  )
}
