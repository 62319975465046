import React, { useEffect, useState } from 'react'
import { Card, Flex, Text } from '../../../Components'
import { useCQuery } from '../../../Services'
import { ScatterChartComponent } from '../../../Components/Charts/ScatterChart'
import { useNavigate } from 'react-router-dom'
import { formatEuro3Decimals } from '../../../Services/Functions'
import { getDate } from './pricingFunctions'

const timeDataKeys = [{ dataKey: 'totalPrice', color: 'red' }]

export const PricingTimeline = ({ dates }) => {
  const { data: { conversations = [] } = {}, isSuccess } = useCQuery(['getStats', ...dates])
  const [totalPrices, setTotalPrices] = useState([])
  const [averagePrice, setAveragePrice] = useState(0)
  const navigate = useNavigate()

  useEffect(() => {
    if (isSuccess) {
      setTotalPrices([
        { createdAt: getDate(dates[0]).getTime(), conversationId: '' },
        ...conversations
          .filter(({ totalPrice }) => totalPrice)
          .map((conversation) => ({
            createdAt: new Date(conversation.createdAt).getTime(),
            totalPrice: conversation?.totalPrice || 0,
            conversationId: conversation.id
          })),
        { createdAt: getDate(dates[1]).getTime() + 86400000, conversationId: '' }
      ])
      setAveragePrice(conversations.reduce((acc, { totalPrice }) => acc + totalPrice, 0) / conversations.length)
    }
  }, [conversations])

  if (!isSuccess) return null
  return (
    <Card style={{ width: '100%' }}>
      <Text title bold value='COSTO CONVERSAZIONI' style={{ marginRight: 20, marginBottom: 10, width: '100%' }} size={20} center />
      <Text value={`Costo medio nel periodo selezionato: ${formatEuro3Decimals(averagePrice)}`} style={{ marginBottom: 10 }} />
      <Flex fw style={{ height: '50vh', marginTop: 15 }}>
        <ScatterChartComponent
          data={totalPrices} dataKeys={timeDataKeys} xaxis='createdAt' yaxis cartesian
          xAxisProps={{
            dataKey: 'createdAt',
            type: 'number',
            tickCount: 3,
            domain: ['dataMin', 'dataMax'],
            tickFormatter: (unixTime) => new Date(unixTime).toLocaleString(),
            interval: 'preserveStartEnd'
          }}
          yAxisProps={{
            type: 'number',
            dataKey: 'totalPrice',
            tickFormatter: (value) => formatEuro3Decimals(value)
          }}
          tooltipProps={{
            content: ({ payload: [{ payload: { conversationId = '', totalPrice } = {} } = {}] = [] }) => (
              <div>
                <Text value={`Id Conversazione: ${conversationId}`} />
                <Text value={`Costo totale: ${formatEuro3Decimals(totalPrice)}`} />
              </div>)
          }}
          scatterProps={{
            onClick: ({ payload: { conversationId = '' } = {} }) => navigate(`/pricing/${conversationId}`)
          }}
        />
      </Flex>
    </Card>
  )
}
