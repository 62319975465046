import React from 'react'
import { Button, Flex, Text } from '../../../Components'

export function ModalEscludiNora ({ onHide, onConfirm, callSpinnerEscludiNora }) {
  return (
    <Flex fw>
      <Text value='Vuoi escludere Nora dalla conversazione?' title bold size={30} />
      <Text value='I prossimi messaggi dovranno essere gestiti solamente da middle office' />
      <Flex fw row jb style={{ marginTop: 20 }}>
        <Button label='Annulla' onClick={onHide} style={{ marginRight: 20 }} />
        <Button label='Conferma' onClick={onConfirm} spinner={callSpinnerEscludiNora} />
      </Flex>
    </Flex>
  )
}
