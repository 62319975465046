import { useNavigate, useParams } from 'react-router-dom'
import { saveSociety, useSociety } from './Functions'
import { Button, Flex, Input, Spinner, Text } from '../../../../Components'

// const defaultStyle = { width: '31%', marginRight: '1%', marginLeft: '1%', marginBottom: 15 }
const fields = {
  societyName: { label: 'Nome' },
  codSociety: { label: 'Codice Società' },
  atlanteUrl: { label: 'Url Atlante' },
  userWS: { label: 'Utente WS' },
  passwordWS: { label: 'Password WS' },
  b4bEnabled: { label: 'Abilitazione B4B ( 0=false, 1=true )' },
  b4bDbUrl: { label: 'Url B4B' },
  b4bDbUser: { label: 'Utente B4B' },
  b4bDbPassword: { label: 'Password B4B' },
  b4bDbName: { label: 'Nome DB B4B' },
  dinamicoPhpUrl: { label: 'Url Dinamico' },
  dinamicoDbUrl: { label: 'Url DB Dinamico' },
  dinamicoDbUser: { label: 'Utente DB Dinamico' },
  dinamicoDbPassword: { label: 'Password DB Dinamico' },
  dinamicoDbName: { label: 'Nome DB Dinamico' },
  googleApiKey: { label: 'Google API Key' },
  tomcatCode: { label: 'Codice Tomcat' },
  whatsappPhone: { label: 'Telefono Whatsapp' },
  maxAIMessages: { label: 'Numero massimo iterazioni AI' },
  enableCampi1a10: { label: 'Abilitazione estrapolazione AI campi 1 a 10 ( 0=false, 1=true )' },
  enableResumeOperator: { label: 'Abilitazione invio email riassuntivo operatore ( 0=false, 1=true )' },
  skipTrainStationSearch: { label: 'Salto ricerca treni su dinamico ( 0=fa la ricerca, 1=salta )' },
  enableReadAttachments: { label: 'Abilitazione della lettura degli allegati email ( 0=non legge gli allegati, 1=lettura allegati )' },
  enableReport: { label: 'Visualizza bottone report ( 0=disabilitato, 1=abilitato )' },
  canTakeCharge: { label: 'Abilitazione "presa in carico" ( 0=false, 1=true )' },
  reportCCEmail: { label: 'Elenco Email in CC a report (separati da virgola)', type: 'array' },
  disableErrorMessageToOperator: { label: 'Blocca invio email di errore agli operatori ( 0=invia email, 1=blocca invio )' },
  enableReadHistoryPax: { label: 'Abilitazione lettura dello storico del profilo viaggiatore ( 0=non legge lo storico, 1=lettura storico )' }
}

export function SocietyEditor () {
  const { societyId } = useParams()
  const navigate = useNavigate()
  const [society, setSociety, isSuccess] = useSociety(societyId)
  const { id = '' } = society || {}

  const save = async () => {
    const res = await saveSociety(society)
    res?.id && navigate('/admin/society/list')
    return res
  }

  if (!isSuccess || !society) return <Spinner />

  const isNew = id === 'new'

  return (
    <Flex fw>
      <Text value={`${isNew ? 'Crea' : 'Modifica'} Società`} title bold size={30} />
      <Flex fw row wrap style={{ marginTop: 20, gap: 10 }}>
        {Object.keys(fields).map((key) =>
          fields[key].type === 'array'
            ? (
              <Flex key={key} fw row style={{ marginTop: 15, width: '40%' }}>
                <Text value={fields[key].label} bold style={{ marginRight: 10 }} />
                <Input
                  value={society[key]?.join(',')} id={key}
                  onChange={({ [key]: value }) => setSociety(key, value.split(',').map((v) => v.trim()))} style={{ width: '100%' }}
                />
              </Flex>
              )
            : (
              <Input
                key={key} value={society[key]}
                onChange={({ [key]: value }) => setSociety(key, value)}
                label={fields[key]?.label} id={key} style={{ marginTop: 15, width: '40%' }}
              />)
        )}
        <Flex fw row style={{ marginTop: 20 }}>
          <Button label='Salva' icon='check' style={{ marginRight: 10 }} onClick={save} />
          <Button label='Torna indietro' icon='left' onClick={() => navigate('/admin/society/list')} />
        </Flex>
      </Flex>
    </Flex>
  )
}
