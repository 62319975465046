import React from 'react'
import { Flex, Button } from '../../../Components'
import { setAllErrorsWithKeyLabel, setAllErrorsTAProfile, setAllErrorOutOfOffice } from './panelOperationsFunctions'
export const PanelOperationsAdmin = () => {
  return (
    <Flex fw fh>
      <Flex>
        <Button label='Aggiorna label errori' onClick={setAllErrorsWithKeyLabel} />
      </Flex>
      <Flex style={{ marginTop: '10px' }}>
        <Button label='Aggiorna errori TA profile' onClick={setAllErrorsTAProfile} />
      </Flex>
      <Flex style={{ marginTop: '10px' }}>
        <Button label='Aggiorna errori out of office' onClick={setAllErrorOutOfOffice} />
      </Flex>

    </Flex>
  )
}
