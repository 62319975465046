import React from 'react'

import { Text, Flex, Card } from '../../Components'

export const InfoTotalPrice = ({ totalPriceGeneral, trasfertaConversation, openPauseConversation, handledByOperator, labelColor, costPerConversation }) => {
  return (
    <Card style={{ width: '100%', height: '100%', justifyContent: 'center', alignContent: 'center' }}>
      <Flex fw fh>
        <Text bold size={20} value='Totale costo' />
        <Text bold value={(totalPriceGeneral?.total || 0).toFixed(2) + '€'} size={30} color='red' />
        <Text value={'n° conversazioni: ' + (totalPriceGeneral?.counter || 0)} />
        <Text italic value={'Costo a conversazione: €' + (costPerConversation?.toFixed(2) || 0)} style={{ marginTop: 10 }} size={10} />
      </Flex>
    </Card>
  )
}
