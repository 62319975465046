import React, { useEffect, useState, useRef } from 'react'
import { Button, Flex, Spinner, Text } from '../../../Components'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { getCorrectState, getLabelMidofficeRef, getServiceCount, getStateDefaultData, getStateFromInfoServices } from './Functions'
import { useParams } from 'react-router-dom'
import { ConfirmModal } from './Components'
import { getLabelDescriptionError, getStateValues } from '../GigiList/Functions'
import { TransferSection, PlaneSection, TrainSection, HotelSection, TravellerSection, Campi1a10Section } from './Sections'
import { useAuth } from '../../../Provider/AuthProvider'
import { SearchTravellerSection } from './Sections/SearchTraveller'
import { FormChat } from './FormChat'
import { getDiffObj } from '../../Test/AutoTest/Functions'
import { CarSection } from './Sections/Car'
import { getCampi1a10 } from './Sections/Campi1a10'

const defaultConfirmType = 'createTransfer'
const { ERROR, RUNNING, READYTORESTART } = getStateValues()

const servicesType = [{ id: 'plane', title: 'SERVIZI AEREO' }, { id: 'train', title: 'SERVIZI TRENO' }, { id: 'hotel', title: 'SERVIZI HOTEL' }, { id: 'car', title: 'SERVIZI AUTO' }]

const formActions = {
  createTransfer: { buttonLabel: 'Crea trasferta', unitName: 'confirmGigiForm' },
  modifyTransfer: { buttonLabel: 'Conferma Modifica', unitName: 'confirmGigiFormModify' }
}

export function Form () {
  const { user: { user = {} } = {} } = useAuth()
  const { conversationId } = useParams()
  const { data: conversation = {}, isSuccess } = useCQuery(['conversation', conversationId])
  const stateRef = useRef()
  const [readOnly, setReadOnly] = useState(false)
  const [btnTakeCharge, setBtnTakeCharge] = useState(false)
  const [modalSendVisible, setModalSendVisible] = useState(false)
  const [modalTakeChargeVisible, setModalTakeChargeVisible] = useState(false)
  const [stateOnDB, setStateOnDB] = useState()
  const [render, setRender] = useState(false)
  const [lastProcessStepId, setLastProcessStepId] = useState()
  const [travellerIsPresent, setTravellerIsPresent] = useState(true)
  const [lastStatus, setLastStatus] = useState()
  const [confirmType, setConfirmType] = useState()
  const [diffModify, setDiffModify] = useState({})

  const setState = (newState, forceRender) => {
    const mustRender = [
      servicesType.some(({ id }) => getServiceCount(newState, id) !== getServiceCount(stateRef.current, id)),
      getCampi1a10(newState)?.length !== getCampi1a10(stateRef.current)?.length,
      forceRender
    ]?.some((v) => v)
    stateRef.current = newState
    mustRender && setRender(!render)
  }

  useEffect(() => {
    if (isSuccess) {
      const lastProcess = conversation?.processes[conversation?.processes.length - 1]
      const lastStatus = lastProcess?.state
      setLastStatus(lastStatus)
      const lastProcessStep = conversation?.lastProcessStep
      const lastPayload = lastProcessStep?.initialState || {}
      const profiloViaggiatore = lastPayload?.transferInfo?.infoPax?.listOfPax[0]?.profileData?.profiloViaggiatoreWS02

      if ((!profiloViaggiatore?.keyProfilo?.personalID || !profiloViaggiatore?.datiCliente?.codiceCliente) && lastStatus !== RUNNING) {
        setTravellerIsPresent(false)
      }

      const { transferInfo = {}, communityPreset = {} } = lastPayload || {}
      const { infoPax = {} } = transferInfo || {}
      const { listOfPax = [] } = infoPax || {}
      const { communityPresetTraveller = {} } = listOfPax[0] || {}
      let selectedSteps
      if (Object.keys(communityPresetTraveller).length) selectedSteps = communityPresetTraveller?.selectedSteps
      else selectedSteps = communityPreset?.selectedSteps
      // posso editare il form nel caso in cui il processo sia in pausa (quindi senza errori)
      // oppure se il processo è in errore / redirect operator e sono ancora in fase di raccolta dati

      const editable = (
        conversation?.status === 'paused' || // se è in pausa, oppure
        (lastStatus === ERROR && !lastPayload?.allDataElaborated && lastPayload?.gigiFormEditable) || // se ultimo processo in errore ed è ancora in fase di raccolta dati, oppure
        (conversation?.status === 'handledByOperator' && lastStatus !== RUNNING) // in carico a operatore e non in running
      ) &&
        (selectedSteps !== 0) && // e se sulla config della community non è stato selezionato il primo avanzamento
        conversation?.noraIsIncluded // nora è inclusa nella conversazione

      const newReadOnly = !editable

      if ((newReadOnly !== readOnly) || !stateRef.current || lastProcessStepId !== lastProcessStep?.id || lastProcessStep?.initialState?.forceUpdate) {
        setConfirmType(defaultConfirmType)
        setLastProcessStepId(lastProcessStep?.id)
        const stateDefaultData = getStateDefaultData(lastPayload)
        setStateOnDB(stateDefaultData)
        setReadOnly(newReadOnly)
        setState(stateDefaultData, true)
        if (defaultConfirmType) {
          const { transferInfo: { infoPax: { listOfPax: [{ infoServices = {}, oldInfoServices = {} } = {}] = [] } = {} } = {} } = stateDefaultData || {}
          if (Object.keys(oldInfoServices).length && Object.keys(infoServices).length) {
            setDiffModify(getDiffObj(getStateFromInfoServices(oldInfoServices), getStateFromInfoServices(infoServices)))
          }
        }
      }
      setBtnTakeCharge(conversation?.status !== 'handledByOperator' && conversation?.status !== 'ended' && conversation?.status !== 'error' && lastStatus !== RUNNING && lastStatus !== READYTORESTART && user?.environment?.canTakeCharge)
    }
  }, [isSuccess, conversation])

  if (!isSuccess || !stateRef.current) return <Spinner />

  const handleSend = async () => {
    try {
      const stateChanged = !(JSON.stringify(stateRef.current) === JSON.stringify(stateOnDB))
      const correctState = { ...getCorrectState(stateRef.current), sentFromGigiForm: true }
      const unitModifyOrCreate = formActions[confirmType]?.unitName
      await FC.service('info').create({ action: unitModifyOrCreate, state: correctState, conversationId, unitName: unitModifyOrCreate, stateChanged })
      invalidateQuery(['conversation', 'conversations'])
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'Form inviato con successo' })
    } catch (e) {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore conferma form' })
    }
  }

  const updateState = async () => {
    try {
      const correctState = { ...getCorrectState(stateRef.current), sentFromGigiForm: true }
      await FC.service('info').create({ action: 'updateState', state: correctState, conversationId })
      invalidateQuery(['conversation', 'conversations'])
      window.growl.show({ severity: 'success', summary: 'Successo', detail: 'State aggiornato con successo' })
    } catch (e) {
      return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore aggiornamento state' })
    }
  }

  const handleTakeCharge = async () => {
    await FC.client.service('conversations').patch(conversationId, { status: 'handledByOperator' })
    invalidateQuery(['conversation', 'conversations'])
  }

  const handleClearTraveller = () => {
    const lastProcessStep = conversation?.lastProcessStep
    const lastPayload = lastProcessStep?.initialState || {}
    lastPayload.transferInfo.infoPax.listOfPax[0].profileData = {}

    setLastProcessStepId(lastProcessStep?.id)
    setStateOnDB(getStateDefaultData(lastPayload))
    setState(getStateDefaultData(lastPayload), true)
    setTravellerIsPresent(false)
  }

  if (!stateRef.current) return <Spinner />
  return (
    <Flex fw as row>
      <Flex style={{ width: '65%' }} id='gigiForm'>
        <Flex style={{ height: 30, marginTop: -10, marginBottom: 10 }} row>
          {lastStatus === RUNNING
            ? (
              <Flex row>
                <Text value='Elaborazione messaggio in corso' style={{ fontWeight: 'bold' }} />
                <Spinner size={20} style={{ marginLeft: 10, marginTop: 1 }} />
              </Flex>)
            : confirmType === 'modifyTransfer' && <Text value={'Modifica Trasferta ' + getLabelMidofficeRef(stateRef?.current)} style={{ fontWeight: 'bold' }} />}
        </Flex>
        {
          conversation?.annoTrasferta && conversation?.numeroTrasferta &&
            <Flex row>
              <Text value={'Identificativo Trasferta: ' + conversation?.annoTrasferta + ' - ' + conversation?.numeroTrasferta} bold style={{ marginBottom: 20, color: 'rgb(11, 200, 11)' }} />
            </Flex>
        }
        {!travellerIsPresent && (<SearchTravellerSection state={[stateRef.current, setState]} travellerIsPresent={[travellerIsPresent, setTravellerIsPresent]} />)}
        {travellerIsPresent && (<TravellerSection state={[stateRef.current, setState]} readOnly={readOnly} onClickClear={handleClearTraveller} />)}
        <TransferSection state={[stateRef.current, setState]} readOnly={readOnly} />
        {!!user?.environment?.enableCampi1a10 && <Campi1a10Section state={[stateRef.current, setState]} readOnly={readOnly} />}
        <PlaneSection state={[stateRef.current, setState]} readOnly={readOnly} diff={diffModify?.plane || []} />
        <TrainSection state={[stateRef.current, setState]} readOnly={readOnly} diff={diffModify?.train || []} />
        <HotelSection state={[stateRef.current, setState]} readOnly={readOnly} diff={diffModify?.hotel || []} />
        <CarSection state={[stateRef.current, setState]} readOnly={readOnly} diff={diffModify?.car || []} />
        <ConfirmModal header='Conferma Invio' label='Vuoi confermare i dati?' onConfirm={handleSend} visible={[modalSendVisible, setModalSendVisible]} />
        <ConfirmModal header='Prendi in carico' label='Vuoi prendere in carico la conversazione?' onConfirm={handleTakeCharge} visible={[modalTakeChargeVisible, setModalTakeChargeVisible]} />
        <Flex row style={{ marginTop: 20 }}>
          {!readOnly && user?.role === 'admin' && <Button icon='attention' label='Salva' onClick={updateState} style={{ marginRight: 20 }} />}
          {!readOnly &&
            <Button
              icon='check' label={formActions[confirmType]?.buttonLabel}
              onClick={() => {
                if (document.querySelectorAll('.p-invalid')?.length) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Compilare tutti i campi obbligatori' })
                if (!travellerIsPresent) return window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire i dati del viaggiatore' })
                setModalSendVisible(true)
              }} style={{ marginRight: 20 }}
            />}
          {!!btnTakeCharge && <Button icon='accout-import' label='Prendi in carico' onClick={() => setModalTakeChargeVisible(true)} />}

          {conversation.errorCode &&
            <Text value={getLabelDescriptionError(conversation.errorCode) + ' - Gestire manualmente da Midoffice'} style={{ color: 'red' }} />}
        </Flex>

      </Flex>
      <Flex style={{ width: '34%', marginTop: -10, marginLeft: '1%', position: 'sticky', top: -10 }}>
        <FormChat stateRef={stateRef} /> {/* lato destro della pagina con chat e pulsanti */}
      </Flex>
    </Flex>
  )
}
