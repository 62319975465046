import * as XLSX from 'xlsx'
export function handleGenerateExcel (startDate, endDate, data) {
  // Definisci le date (puoi anche recuperarli da uno state o props)
  // Chiama la funzione per generare il report
  const report = generateReport(startDate, endDate, data)

  // Definiamo l'header nell'ordine richiesto
  const header = [
    'Data',
    'richieste gestite',
    'richieste totali',
    'trasferte create (%)', 'in attesa (%)', 'In carico operatore (%)', 'errori (%)', 'Nora esclusa da op. (%)', 'inoltrate ad operatore (%)'
    // 'trasferte create (n°/tot)', 'in attesa (n°/tot)', 'In carico operatore (n°/tot)', 'errori (n°/tot)', 'Nora esclusa da op. (n°/tot)', 'inoltrate ad operatore (n°/tot)'
  ]
  const wsData = [header]

  // Definiamo l'ordine delle categorie nell'ordine corretto
  const categories = [
    'trasfertaConversations',
    'openPauseConversation',
    'handledByOperator',
    'realError',
    'noraIsExcluded',
    'errorHandled'
  ]

  // Per ogni giorno del report, creiamo una riga
  for (const day in report) {
    const row = [day]
    row.push(report?.[day]?.filteredConversation || 0)
    row.push(report?.[day]?.totalConversations || 0)
    categories.forEach(category => {
      const { perc } = report[day][category] || { perc: 0.00, conv: '0/0', count: 0 }
      row.push(perc)
    })
    /*  categories.forEach(category => {
      const { conv } = report[day][category] || { perc: 0.00, conv: '0/0', count: 0 }
      row.push(conv)
    }) */
    wsData.push(row)
  }

  // Crea un nuovo workbook e un worksheet con i dati
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(wsData)
  XLSX.utils.book_append_sheet(wb, ws, 'Report')

  // Genera il file Excel e triggera il download
  XLSX.writeFile(wb, `report_${startDate}_${endDate}.xlsx`)
}

function generateReport (startDateStr, endDateStr, data) {
  // 1. Parsing delle date
  const startDate = parseDateStatistics(startDateStr)
  const endDate = parseDateStatistics(endDateStr)

  // Estrapoliamo la chiave conversations, che contiene tutte le conversazioni
  const { conversations, ...otherCategories } = data

  // 2. Creare l'intervallo di date
  const dateRange = getDateRangeArray(startDate, endDate)

  // 3. Inizializza il report
  const report = {}

  dateRange.forEach(day => {
    const dayStr = formatDateStatistics(day)
    report[dayStr] = {}

    // Totale conversazioni del giorno dalla chiave "conversations"
    const totalConversations = conversations.filter(event => {
      const eventDayStr = formatDateStatistics(new Date(event.createdAt))
      return eventDayStr === dayStr
    }).length

    // Numero di errorHandled del giorno (la chiave errorHandled è presente in otherCategories)
    const errorHandledCount = data.errorHandled.filter(event => {
      const eventDayStr = formatDateStatistics(new Date(event.createdAt))
      return eventDayStr === dayStr
    }).length

    // Per le altre categorie, il denominatore è:
    const filteredConversation = totalConversations - errorHandledCount

    // 4. Calcola i valori per ciascuna categoria
    for (const category in otherCategories) {
      const count = otherCategories[category].filter(event => {
        const eventDayStr = formatDateStatistics(new Date(event.createdAt))
        return eventDayStr === dayStr
      }).length

      if (category === 'errorHandled') {
        // Calcolo per errorHandled (basato sul totale delle conversazioni)
        const perc = totalConversations > 0 ? parseFloat(((count / totalConversations) * 100).toFixed(2)) : 0.00
        const conv = totalConversations > 0 ? `${count}/${totalConversations}` : '0/0'
        report[dayStr][category] = { perc, conv }
      } else {
        // Calcolo per le altre categorie (basato su filteredConversation)
        const perc = filteredConversation > 0 ? parseFloat(((count / filteredConversation) * 100).toFixed(2)) : 0.00
        const conv = filteredConversation > 0 ? `${count}/${filteredConversation}` : '0/0'
        report[dayStr][category] = { perc, conv, count, total: filteredConversation }
      }
    }
    report[dayStr].totalConversations = totalConversations
    report[dayStr].filteredConversation = filteredConversation
  })

  return report
}

function parseDateStatistics (str) {
  const [day, month, year] = str.split('/')
  return new Date(year, month - 1, day)
}

function getDateRangeArray (startDate, endDate) {
  const dates = []
  const currentDate = new Date(startDate)

  while (currentDate.getTime() <= endDate.getTime()) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
}

function formatDateStatistics (date) {
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  return `${year}-${month}-${day}`
}
