import { Flex, Text } from '../../Components'

const LABEL_HANDLED_ERROR = {
  richiestaModificaOCancellazioneTrasferta: 'Ric. mod./canc. trasferta',
  nessunProfiloTrovatoTA: 'TA mancante sul viaggiatore',
  noProfileInfoTrovato: 'Profilo non trovato',
  passeggeriMultipli: 'Passeggeri multipli',
  attachmentsInEmail: 'Allegati interni in email',
  outOfOffice: 'Out of office',
  canceled: 'Cancellate da operatore',
  default: 'Altro'
}

export const DetailsErrorHandled = ({ errorHandled }) => {
  const totale = errorHandled.length
  const updateErrorHandled = errorHandled?.map((item) => {
    if (item.status === 'canceled') {
      item.errorCode = 'canceled'
    }
    return item
  })
  const raggruppati = updateErrorHandled.reduce((acc, curr) => {
    const codice = curr.errorCode
    if (!acc[codice]) {
      acc[codice] = {
        perc: 0,
        data: []
      }
    }
    acc[codice].data.push(curr)
    return acc
  }, {})

  for (const codice in raggruppati) {
    const count = raggruppati[codice].data.length
    raggruppati[codice].perc = parseFloat(((count / totale) * 100).toFixed(2))
  }
  return (
    <Flex style={{ marginTop: 5 }} fw>
      {Object.keys(raggruppati)
        .sort((a, b) => raggruppati[b].perc - raggruppati[a].perc)
        .map((key, index) => {
          return (
            <Flex key={key} fw row jb as>
              <Text value={`${LABEL_HANDLED_ERROR[key] || LABEL_HANDLED_ERROR.default} (${raggruppati[key]?.data?.length || 0})`} />
              <Flex row jb style={{ width: 100 }}>
                <Text bold value={`${raggruppati[key].perc} %`} color='#4394ff' />
              </Flex>
            </Flex>
          )
        })}
    </Flex>
  )
}
