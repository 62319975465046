import React, { useState, useEffect } from 'react'
import { Flex, Spinner, DateSelectRange, Text, Button } from '../../../Components'
import { PricingTimeline } from './PricingTimeline'
import { useCQuery } from '../../../Services'
import { useNavigate } from 'react-router-dom'
import { useCapsule } from '../../../State/Functions'
import { cap } from '../../../State'
import { PieTotalPricingType } from './PieTotalPricingType'

export const DashboardChartInfoPricing = () => {
  const navigate = useNavigate()
  const [startDate, setStartDate] = useCapsule(cap.selectedStartDateDashboard)
  const [endDate, setEndDate] = useCapsule(cap.selectedEndDateDashboard)
  const [totalCostPerProvider, setTotalCostPerProvider] = useState([])
  const [providers, setProviders] = useState([])
  // const { isSuccess } = useCQuery(['getStats', startDate, endDate])
  const { data: conversationDataPricing, isSuccess: isSuccessPricing } = useCQuery(['totalPricing', startDate, endDate])

  const settingsAllDataDashboard = (data) => {
    const {
      generalPricing, // totale
      trasfertaPricing, // trasferta creata
      errorPricing, // error fatti
      noraIsExcludedPricing, // nora esclusa
      errorHandledPricing, // errori gestiti
      openPauseConversation // in pausa, in attesa di creazione trasferta o dati mancanti e comunicazione aperta
    } = data || {}

    const generalProvidersListKey = generalPricing[0]?.providerTotals || {}
    setProviders(Object.keys(generalProvidersListKey)?.map((key) => ({ provider: key, disabled: false, company: generalProvidersListKey[key]?.company })))

    // struttura per pie
    setTotalCostPerProvider({
      general: setTotalCostPerProviderType(generalPricing),
      trasferta: setTotalCostPerProviderType(trasfertaPricing),
      error: setTotalCostPerProviderType(errorPricing),
      noraIsExcluded: setTotalCostPerProviderType(noraIsExcludedPricing),
      errorHandledPricing: setTotalCostPerProviderType(errorHandledPricing),
      openPauseConversation: setTotalCostPerProviderType(openPauseConversation)
    })
  }

  const setTotalCostPerProviderType = (data, structureTotalCostPerProvider) => {
    const providersListKey = data[0]?.providerTotals || {}
    if (providersListKey) {
      const listData = Object.keys(providersListKey)?.map(key => ({ name: key, value: providersListKey[key]?.total })).filter(item => item.value) || []
      return listData
    }
  }

  useEffect(() => {
    if (isSuccessPricing && !providers?.length) {
      settingsAllDataDashboard(conversationDataPricing)
    }
  }, [conversationDataPricing])

  return !isSuccessPricing
    ? <Spinner />
    : (
      <Flex>
        <Flex>
          <Button label='Torna indietro' icon='left' onClick={() => navigate('/statistics/')} />
        </Flex>
        <Flex row fw style={{ width: '100%', marginBottom: 10, marginTop: 30 }}>
          <DateSelectRange startDate={[startDate, setStartDate]} endDate={[endDate, setEndDate]} />
        </Flex>
        <Flex row jb style={{ marginTop: 30 }}>
          <Flex style={{ height: 250, width: 500, paddingLeft: 30 }}>
            <Text bold value={'Generale ' + (conversationDataPricing?.generalPricing?.[0]?.total?.toFixed(2) || '0,00') + '€'} size={20} />
            <PieTotalPricingType data={totalCostPerProvider.general || []} />
          </Flex>
          <Flex style={{ height: 250, width: 500, paddingLeft: 30 }}>
            <Text bold value={'Trasferte create ' + (conversationDataPricing?.trasfertaPricing?.[0]?.total?.toFixed(2) || '0,00') + '€'} size={20} />
            <PieTotalPricingType data={totalCostPerProvider.trasferta || []} />
          </Flex>
          <Flex style={{ height: 250, width: 500, paddingLeft: 30 }}>
            <Text bold value={'Open o in pausa ' + (conversationDataPricing?.openPauseConversation?.[0]?.total?.toFixed(2) || '0,00') + '€'} size={20} />
            <PieTotalPricingType data={totalCostPerProvider.openPauseConversation || []} />
          </Flex>
        </Flex>
        <Flex row jb style={{ marginTop: 30 }}>
          <Flex style={{ height: 250, width: 500, paddingLeft: 30 }}>
            <Text bold value={'Errori gestiti ' + (conversationDataPricing?.errorHandledPricing?.[0]?.total?.toFixed(2) || '0,00') + '€'} size={20} />
            <PieTotalPricingType data={totalCostPerProvider.errorHandledPricing || []} />
          </Flex>
          <Flex style={{ height: 250, width: 500, paddingLeft: 30 }}>
            <Text bold value={'Nora esclusa da op. ' + (conversationDataPricing?.noraIsExcludedPricing?.[0]?.total?.toFixed(2) || '0,00') + '€'} size={20} />
            <PieTotalPricingType data={totalCostPerProvider.noraIsExcluded || []} />
          </Flex>
          <Flex style={{ height: 250, width: 500, paddingLeft: 30 }}>
            <Text bold value={'Errore ' + (conversationDataPricing?.errorPricing?.[0]?.total?.toFixed(2) || '0,00') + '€'} size={20} />
            <PieTotalPricingType data={totalCostPerProvider.error || []} />
          </Flex>
        </Flex>

        <PricingTimeline dates={[startDate, endDate]} />
      </Flex>
      )
}
