import { FC, invalidateQuery } from '../../Services'

/**
 * Gestisce la marcatura delle conversazioni come fatturate in un intervallo di date specificato.
 * Questa funzione converte le date di inizio e fine fornite nel formato 'DD/MM/YYYY' in oggetti
 * Date per eseguire una query. Aggiorna tutte le conversazioni create all'interno dell'intervallo
 * di date specificato, impostando il flag "billed" su true. In caso di successo, notifica l'utente
 * con un messaggio e aggiorna l'elenco delle conversazioni. In caso di errore, viene visualizzato
 * un messaggio di errore.
 *
 * @param startDate La data di inizio del periodo da considerare, nel formato 'DD/MM/YYYY'.
 * @param endDate La data di fine del periodo da considerare, nel formato 'DD/MM/YYYY'.
 *
 * @autore mranocchia
 */
export async function handleBilledConversation (startDate, endDate, setSpinnerOperation) {
  // convert startDate and endDate to Date objects, the format of starDate and endDate is 'DD/MM/YYYY' convert to new Date for query
  setSpinnerOperation(true)
  const [startDay, startMonth, startYear] = startDate.split('/')
  const [endDay, endMonth, endYear] = endDate.split('/')
  try {
    await FC.service('conversations').patch(null, { billed: true }, {
      query: {
        $and: [
          { createdAt: { $gte: new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0) } },
          { createdAt: { $lte: new Date(endYear, endMonth - 1, endDay, 23, 59, 59, 999) } },
          { billed: false } // non ancora fatturate
        ]
      }
    })

    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Periodo segnato come fatturato' })
    invalidateQuery(['getDashboardStatsConversations'])
    setSpinnerOperation(false)
  } catch (error) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore nel savataggio: ' + error.message })
  }
}
