import { useLocation } from 'react-router-dom'
// import giginoPaneVino from '../Resources/giginoPaneVino.png'
const pathMap = [
  { path: '/login', label: 'Login' },
  { path: '/users', label: 'Utenti' }
]

export const usePageTitle = () => {
  useLocation()
  if (window.location.pathname === '/') return 'Home'

  const matchTitle = ({ path }) => path === window.location.pathname

  return pathMap.find(matchTitle)?.label || 'Home'
}

export const useMenuItems = ({ role }) => ([
  { label: 'Chat', icon: 'fa-duotone fa-paper-plane', path: '/chat/', role: ['admin', 'tester'] },
  { label: 'Statistiche', icon: 'fa-duotone fa-chart-pie', path: '/statistics', role: ['admin', 'operatorCommunity', 'fatturazione'] },
  { label: 'Elenco Società', icon: 'fa-duotone fa-briefcase', path: '/admin/society/list', role: ['admin'] },
  { label: 'Elenco Utenti', icon: 'fa-duotone fa-user-gear', path: '/admin/users/list', role: ['admin'] },
  { label: 'Elenco Viaggiatori', icon: 'fa-duotone fa-users', path: '/admin/travellers/list', role: ['admin'] },
  { label: 'Operazioni', icon: 'fa-duotone fa-list-tree', path: '/operations/list', role: ['admin'] },
  { label: 'Processi', icon: 'fa-duotone fa-arrow-progress', path: '/process/dashboard', role: ['admin'] },
  { label: 'Pannello Community', icon: 'fa-duotone fa-gear', path: '/community/search', role: ['admin', 'dynamicTester', 'tester', 'operatorCommunity'] },
  { label: 'Conversazioni', icon: 'fa-duotone fa-microchip-ai', path: '/operatore', role: ['admin', 'operator', 'tester', 'operatorCommunity'] },
  { label: 'Pannello operazioni', icon: 'fa-duotone fa-folder-gear', path: '/admin/panelOperations', role: ['admin'] },
  { label: 'Admin Coefficienti', icon: 'fa-duotone fa-hammer', path: '/admin/coefficient', role: ['admin', 'dynamicTester'] },
  { label: 'Test Dinamico', icon: 'fa-duotone fa-flask-round-poison', path: '/testDyn', role: ['admin', 'dynamicTester', 'tester'] },
  { label: 'Test Automatico', icon: 'fa-duotone fa-flask-gear', path: '/autotest', role: ['admin'] },
  { label: 'Logout', icon: 'fa-duotone fa-right-from-bracket', path: 'logout' }
]).filter(item => !item.role || item?.role?.includes(role))
