import React, { useEffect, useState } from 'react'
import { Flex, Spinner, DateSelectRange, Text, Button, Card } from '../../Components'
import { useNavigate } from 'react-router-dom'
import { useCQuery } from '../../Services'
import { useCapsule } from '../../State/Functions'
import { cap } from '../../State'
import { useAuth } from '../../Provider/AuthProvider'
import { getPercentage, labelBarChart } from './statisticsFunctions'
import { InfoTotalPrice } from './InfoTotalPrice'
import { HandledPercentageNora } from './HandledPercentageNora'
import { DetailsErrorHandled } from './DetailsErrorHandled'
import { handleGenerateExcel } from './reportFunctions'
import StatisticsCard from './StatisticsCard'
import { handleBilledConversation } from './billedFunctions'

const LABEL_STATISTICS = [
  { label: 'errorHandled', color: '#4394ff', main: true },
  { label: 'elaborated', color: '#2c9c2c', main: true },
  { label: 'handlerCorrectNora', color: '#1ba91b', main: true },
  { label: 'trasfertaConversations', color: 'green' },
  { label: 'openPauseConversation', color: '#39c3b4' },
  { label: 'handledByOperator', color: 'orange' },
  { label: 'realError', color: 'red', error: true },
  { label: 'noraIsExcluded', color: '#f25353', error: true },
  { label: 'errorTotal', color: '#f25353', error: true }

]
const COSTO_PER_CONVERSAZIONE = 0.20
/* const DATA_KEYS = [
  { dataKey: 'value', color: 'orange' }
] */
export const Statistics = () => {
  const [startDate, setStartDate] = useCapsule(cap.selectedStartDateDashboard)
  const [endDate, setEndDate] = useCapsule(cap.selectedEndDateDashboard)
  const [spinnerOperation, setSpinnerOperation] = useState(false)
  const { data, isSuccess } = useCQuery(['getDashboardStatsConversations', startDate, endDate])

  const [percentageStateTotal, setPercentageStateTotal] = useState([])

  const [handledPercentage, setHandledPercentage] = useState({})
  const navigate = useNavigate()
  const { user: { user = {} } = {} } = useAuth()
  const [totalPriceGeneral, setTotalPriceGeneral] = useState({ counter: 0, total: 0 })
  const [totalPriceFatturato, setTotalPriceFatturato] = useState({ counter: 0, total: 0 })

  const loadDataConversation = (dataToLoad) => {
    const {
      conversations = [], // totale
      trasfertaConversations = [], // trasferta creata
      realError = [], // error fatti
      noraIsExcluded, // nora esclusa
      errorHandled = [], // errori gestiti
      openPauseConversation = [], // in pausa, in attesa di creazione trasferta o dati mancanti e comunicazione aperta
      handledByOperator = [] // presi manualmente in carico dall'operatore
    } = data

    const totalConversationsLength = conversations.length - errorHandled.length
    const percentages = {
      errorHandled: getPercentage(conversations.length, errorHandled) || 0,
      trasfertaConversations: getPercentage(totalConversationsLength, trasfertaConversations) || 0,
      openPauseConversation: getPercentage(totalConversationsLength, openPauseConversation) || 0,
      handledByOperator: getPercentage(totalConversationsLength, handledByOperator) || 0,
      realError: getPercentage(totalConversationsLength, realError) || 0,
      noraIsExcluded: getPercentage(totalConversationsLength, noraIsExcluded) || 0,
      elaborated: getPercentage(conversations.length, [...trasfertaConversations, ...openPauseConversation, ...handledByOperator]) || 0,
      errorTotal: getPercentage(conversations.length, [...realError, ...noraIsExcluded]) || 0
    }

    const percentageBarChart = Object.keys(percentages).map(key => ({
      name: key,
      label: labelBarChart(key),
      value: percentages[key]
    }))
    setPercentageStateTotal(percentageBarChart)

    const counter = trasfertaConversations?.length + openPauseConversation?.length + handledByOperator?.length
    setHandledPercentage({
      perc: parseFloat(((percentages?.trasfertaConversations + percentages?.openPauseConversation + percentages?.handledByOperator) || 0).toFixed(2)),
      totalHandled: counter,
      totalError: realError.length + noraIsExcluded.length,
      totalConversationsLength
    })

    if (user?.role === 'fatturazione') {
      const counterBilled = (trasfertaConversations?.filter(item => item?.billed)?.length || 0) + (openPauseConversation?.filter(item => item?.billed)?.length || 0) + (handledByOperator?.filter(item => item?.billed)?.length || 0)
      const counterNotBilled = (trasfertaConversations?.filter(item => !item?.billed)?.length || 0) + (openPauseConversation?.filter(item => !item?.billed)?.length || 0) + (handledByOperator?.filter(item => !item?.billed)?.length || 0)
      setTotalPriceFatturato({
        total: counterBilled * COSTO_PER_CONVERSAZIONE,
        counter: counterBilled
      })
      setTotalPriceGeneral({
        total: counterNotBilled * COSTO_PER_CONVERSAZIONE,
        counter: counterNotBilled
      })
    } else {
      setTotalPriceGeneral({
        total: counter * COSTO_PER_CONVERSAZIONE,
        counter
      })
    }
  }

  useEffect(() => {
    if (isSuccess) {
      loadDataConversation(data)
    }
  }, [data])
  return !isSuccess
    ? <Spinner />
    : (
      <Flex fw fh>
        <Text bold value='Riepilogo statistiche' size={20} />
        {
          user?.role === 'admin' &&
            <Flex row fw style={{ marginTop: 30 }}>
              {spinnerOperation ? <Spinner /> : <Button label='Dashboard €' onClick={() => navigate('/dashboardPricing')} style={{ marginLeft: 20, height: 40, width: 170 }} />}
              {spinnerOperation ? <Spinner /> : <Button label='Report periodo selezionato' onClick={() => handleGenerateExcel(startDate, endDate, data)} style={{ marginLeft: 20, height: 40, width: 170 }} />}
            </Flex>
        }
        {
          user?.role === 'fatturazione' &&
            <>
              {totalPriceGeneral.counter > 0 &&
                <Flex row fw style={{ marginTop: 30 }}>
                  {spinnerOperation ? <Spinner /> : <Button label='Segna periodo come fatturato' onClick={() => handleBilledConversation(startDate, endDate, setSpinnerOperation)} style={{ marginLeft: 20, height: 40, width: 170 }} />}
                </Flex>}
              {totalPriceGeneral.counter === 0 &&
                <Text bold size={20} value='Non ci sono conversazioni da fatturare nel periodo selezionato' style={{ marginTop: 30, color: 'green' }} />}
            </>
        }

        <Flex row fw style={{ width: '100%', marginBottom: 10, marginTop: 30 }}>
          <DateSelectRange startDate={[startDate, setStartDate]} endDate={[endDate, setEndDate]} />
        </Flex>
        <Flex fw row style={{}}>
          <Flex fh>
            <InfoTotalPrice
              totalPriceGeneral={totalPriceGeneral}
              trasfertaConversation={data.trasfertaConversations}
              openPauseConversation={data.openPauseConversation}
              handledByOperator={data.handledByOperator}
              labelColor={LABEL_STATISTICS}
              costPerConversation={COSTO_PER_CONVERSAZIONE}
            />
          </Flex>
          {user?.role === 'fatturazione' &&
            <Flex fh style={{ marginLeft: 10 }}>
              <Card style={{ width: '100%', height: '100%', justifyContent: 'center', alignContent: 'center' }}>
                <Flex fw fh>
                  <Text bold size={20} value='Totale fatturati nel periodo' />
                  <Text bold value={(totalPriceFatturato?.total || 0).toFixed(2) + '€'} size={30} color='green' />
                  <Text value={'n° conversazioni: ' + (totalPriceFatturato?.counter || 0)} />
                  <Text italic value={'Costo a conversazione: €' + (COSTO_PER_CONVERSAZIONE?.toFixed(2) || 0)} style={{ marginTop: 10 }} size={10} />
                </Flex>
              </Card>
            </Flex>}
          {user?.role !== 'fatturazione' &&
            <Flex fh style={{ marginLeft: 10 }}>
              <HandledPercentageNora handledPercentage={handledPercentage} />
            </Flex>}
        </Flex>
        {user?.role !== 'fatturazione' &&
          <Flex fw row style={{ marginTop: 15 }}>

            {/* stato conversazioni elaborate */}
            <StatisticsCard
              title='Conversazioni elaborate'
              countLabel='Dettagli % elaborate'
              countValue={handledPercentage.totalHandled}
              totalCount={data.conversations?.length}
              data={data}
              percentageStateTotal={percentageStateTotal}
              filterMain={LABEL_STATISTICS.filter(item => item.label === 'elaborated')}
              filterDetails={LABEL_STATISTICS.filter(item => !item.main && !item.error)}
              cardStyle={{}}
            />

            {/* situazione inoltrate */}
            <StatisticsCard
              title='Situazioni inoltrate'
              countLabel='Dettagli % inoltrate'
              countValue={data.errorHandled?.length}
              totalCount={data.conversations?.length}
              data={data}
              percentageStateTotal={percentageStateTotal}
              filterMain={LABEL_STATISTICS.filter(item => item.label === 'errorHandled')}
              filterDetails={[]} // Nessun dettaglio sotto le percentuali
              showDetailsComponent
              detailsComponent={<DetailsErrorHandled errorHandled={data.errorHandled} />}
              cardStyle={{ width: 500 }}
            />

            {/* errori fatti */}

            <StatisticsCard
              title='Escluse / errori'
              countLabel='Dettagli % errori'
              countValue={handledPercentage.totalError}
              totalCount={data.conversations?.length}
              data={data}
              percentageStateTotal={percentageStateTotal}
              filterMain={LABEL_STATISTICS.filter(item => item.label === 'errorTotal')}
              filterDetails={LABEL_STATISTICS.filter(item => item.label === 'realError' || item.label === 'noraIsExcluded')}
              cardStyle={{}}
            />
          </Flex>}
      </Flex>
      )
}
