import React from 'react'
import { Flex, Text, Card } from '../../Components'
import { PercentageInfoNumber } from './PercentageInfoNumber'

const StatisticsCard = ({
  title,
  countLabel,
  countValue,
  totalCount,
  data,
  filterMain,
  filterDetails,
  percentageStateTotal,
  showDetailsComponent = false,
  detailsComponent = null,
  cardStyle = {}
}) => {
  return (
    <Flex fh style={{ marginLeft: 10 }}>
      <Card title={`${title} (${countValue || 0}/${totalCount || 0})`} style={{ height: '100%', ...cardStyle }}>
        {filterMain.map((itemLabel, index) => (
          <PercentageInfoNumber
            noTotal
            overlayLabel='Perc. sul totale conv.'
            data={data[itemLabel.label]}
            key={index}
            itemLabel={itemLabel}
            percentageStateTotal={percentageStateTotal}
          />
        ))}
        <Text value={countLabel} bold size={16} style={{ marginTop: 10 }} />
        <Flex style={{ marginTop: 15 }}>
          {filterDetails.map((itemLabel, index) => (
            <PercentageInfoNumber
              data={data[itemLabel.label]}
              key={index}
              itemLabel={itemLabel}
              percentageStateTotal={percentageStateTotal}
            />
          ))}
        </Flex>
        {showDetailsComponent && detailsComponent}
      </Card>
    </Flex>
  )
}

export default StatisticsCard
