import { Button, Card, Flex, Text } from '../../../../Components'
import { CustomHeader, SingleServiceContainer, TransferField } from '../Components'
import { addService, getElementsByService, getServiceCount, readKey, removeService } from '../Functions'

const { car: { pickupPlace, pickupDate, pickupHour, dropoffPlace, dropoffDate, dropoffHour, carCategory, carType, carTransmission, noteAggiuntive = '' } } = getElementsByService()

export const CarSection = ({ state: [state, setState], readOnly }) => {
  const stateProp = [state, setState]
  const statePropRender = [state, (data) => setState(data, true)]

  return (
    <Card key='car' collapsable customHeader={<CustomHeader title='SERVIZI AUTO' />} style={{ width: '100%', marginTop: 20 }}>
      <Flex fw>
        <Flex fw row jb>
          {!getServiceCount(state, 'car') ? <Text value='Nessun servizio inserito' style={{ fontFamily: 'Lexend', width: 300 }} /> : <div />}
          {!readOnly && <Button label='Aggiungi servizio' onClick={async () => setState(await addService(state, 'car'))} />}
        </Flex>
        {Array(getServiceCount(state, 'car')).fill().map((_, i) => {
          const departureDateValue = readKey(state, pickupDate?.location, i)
          const minDateArrival = departureDateValue
            ? new Date(new Date(departureDateValue.split('/')[2], departureDateValue.split('/')[1] - 1, departureDateValue.split('/')[0]))
            : new Date()
          return (
            <SingleServiceContainer key={i} onRemove={() => setState(removeService(state, 'car', i))} readOnly={readOnly}>
              <TransferField state={statePropRender} item={pickupDate} key='pickupDate' count={i} readOnly={readOnly} />
              <TransferField state={stateProp} item={{ ...dropoffDate, minDate: minDateArrival }} key='dropoffDate' count={i} readOnly={readOnly} />
              <TransferField state={stateProp} item={pickupPlace} key='pickupPlace' count={i} readOnly={readOnly} />
              <TransferField state={stateProp} item={pickupHour} key='pickupHour' count={i} readOnly={readOnly} />
              <TransferField state={stateProp} item={dropoffPlace} key='dropoffPlace' count={i} readOnly={readOnly} />
              <TransferField state={stateProp} item={dropoffHour} key='dropoffHour' count={i} readOnly={readOnly} />
              <TransferField state={stateProp} item={carCategory} key='carCategory' count={i} readOnly={readOnly} />
              <TransferField state={stateProp} item={carType} key='carType' count={i} readOnly={readOnly} />
              <TransferField state={stateProp} item={carTransmission} key='carTransmission' count={i} readOnly={readOnly} />
              <TransferField state={stateProp} item={noteAggiuntive} key='noteAggiuntive' count={i} readOnly={readOnly} />
            </SingleServiceContainer>
          )
        })}
      </Flex>
    </Card>
  )
}
