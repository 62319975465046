import { FC } from '../../../Services'

export const setAllErrorsWithKeyLabel = async () => {
  try {
    await FC.service('info').create({ action: 'updateAllConversationsWithError' })
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Update corretto' })
  } catch (error) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Update non andato a buon fine' })
    throw new Error('Errore durante l\'aggiornamento degli errori' + JSON.stringify(error || { err: 'No error defined in setAllErrorsWithKeyLabel' }))
  }
}
export const setAllErrorsTAProfile = async () => {
  try {
    await FC.service('info').create({ action: 'updateAllConversationsErrorTAProfile' })
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Update corretto' })
  } catch (error) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Update non andato a buon fine' })
    throw new Error('Errore durante l\'aggiornamento degli errori' + JSON.stringify(error || { err: 'No error defined in setAllErrorsWithKeyLabel' }))
  }
}
export const setAllErrorOutOfOffice = async () => {
  try {
    await FC.service('info').create({ action: 'updateAllConversationsErrorOutOfOffice' })
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Update corretto' })
  } catch (error) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Update non andato a buon fine' })
    throw new Error('Errore durante l\'aggiornamento degli errori' + JSON.stringify(error || { err: 'No error defined in setAllErrorsWithKeyLabel' }))
  }
}
