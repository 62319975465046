export const getPercentage = (totalConversations, count) =>
  totalConversations > 0
    ? parseFloat((Math.ceil((count.length / totalConversations) * 10000) / 100).toFixed(2)) || 0
    : 0

export const labelBarChart = (item) => {
  switch (item) {
    case 'trasfertaConversations':
      return 'Trasferte create'
    case 'errorHandled':
      return 'Inoltrate ad operatore'
    case 'realError':
      return 'Errori'
    case 'noraIsExcluded':
      return 'Nora esclusa'
    case 'openPauseConversation':
      return 'In attesa di conferma'
    case 'handledByOperator':
      return 'In carico ad un operatore'
    case 'handlerCorrectNora':
      return 'Correttamente gestite'
    default:
      return item
  }
}
