export const getProcessState = (conv) => {
  const value = conv?.processes
    ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    ?.[0]?.state
  if (conv.status === 'paused') return ({ label: 'In attesa di conferma', value })
  if (conv.status === 'canceled') return ({ label: 'Cancellata', value })
  if (value === 3 && conv.status === 'ended') {
    if (conv.numeroTrasferta) return ({ label: 'Trasferta creata', value })
    return ({ label: 'Terminato', value })
  }
  if (value === 3 && conv.status === 'error') {
    if (!conv.noraIsIncluded) return ({ label: 'Nora Esclusa', value }) // Label di esclusione di Nora
    return ({ label: 'Terminato - ' + (jsonErrors?.[conv.errorCode] || jsonErrors.default), value })
  }
  return ({ label: STATE_ENUM?.find((e) => e.value === value)?.label, value })
}

export const STATE_ENUM = [
  { label: 'Creato', value: 1, code: 'INITIAL', color: 'blue' },
  { label: 'In esecuzione', value: 2, code: 'RUNNING', color: 'violet' },
  { label: 'Terminato', value: 3, code: 'ENDED', color: 'green' },
  { label: 'Errore', value: 5, code: 'ERROR', color: 'red' },
  { label: 'In esecuzione', value: 7, code: 'READYTORESTART', color: 'orange' },
  { label: 'Cancellata', value: 8, code: 'CANCELLED', color: 'yellow' }
]

export const getStateValues = () => {
  const obj = {}
  STATE_ENUM.forEach((e) => { obj[e.code] = e.value })
  return obj
}

export function getLabelDescriptionError (error) {
  return jsonErrors?.[error] || jsonErrors.default
}

const jsonErrors = {
  passeggeriMultipli: 'Passeggeri multipli identificati',
  attachmentsInEmail: 'Allegati nell\'email',
  nessunProfiloTrovato: 'Profilo non trovato',
  noProfileInfoTrovato: 'Profilo viaggiatore non trovato',
  nessunProfiloTrovatoTA: 'Legame viaggiatore e TA non trovato',
  duplicazioneProfiloTrovato: 'Duplicazione profilo viaggiatore, trovata omonimia',
  richiestaModificaOCancellazioneTrasferta: 'Richiesta modifica o cancellazione trasferta',
  outOfOffice: 'Out of office',
  default: 'Gestire da Midoffice'
}
