import { useEffect, useState } from 'react'
import { useTransferField } from './Hook'
import { Flex } from '../../../../Components'
import { AutoComplete } from 'primereact/autocomplete'
import { getColorDiff } from './Functions'

export const AirportInput = ({ state: [state, setState], item, count, readOnly, diffStatus }) => {
  const { location, label, id, options, notRequired = false } = item
  const [iataCode, setIataCode] = useTransferField([state, setState], location?.value, count)
  const [iataCodeErr, setIataCodeErr] = useState(false)
  const [, setAirportDesc] = useTransferField([state, setState], location?.label, count)
  const onSelect = ({ label, value }) => { setIataCode(value); setAirportDesc(label) }
  const [suggestionsIataCode, setSuggestionsIataCode] = useState([])

  useEffect(() => {
    setIataCodeErr((!iataCode && !notRequired) || (iataCode && !options?.find(({ value }) => value === iataCode)))
  }, [iataCode])

  const searchIataCode = (event) => {
    if (!event.query.trim().length) setSuggestionsIataCode([...options])
    else {
      setSuggestionsIataCode(([
        ...options.filter(({ value }) => value.toLowerCase().startsWith(event.query.toLowerCase())),
        ...options.filter(({ label }) => label.toLowerCase().includes(event.query.toLowerCase()))
      ]))
    }
  }

  return (
    <Flex key={item.id + '1'} as js style={{ width: '23%', margin: '5px 1%' }}>
      <div className='card flex justify-content-center' style={{ marginTop: 25, width: '100%' }}>
        <span className='p-float-label'>
          <AutoComplete
            disabled={readOnly} field='value' className={iataCodeErr ? 'p-invalid' : ''} id={id} onSelect={(e) => onSelect(e.value)} style={{ width: '100%' }}
            value={iataCode} suggestions={suggestionsIataCode} completeMethod={searchIataCode} onChange={(e) => { setIataCode(e.value); setAirportDesc('') }}
            inputStyle={{ fontFamily: 'Lexend', fontSize: 14, width: '100%', color: getColorDiff(diffStatus, iataCodeErr) }} forceSelection itemTemplate={({ both = '' }) => both}
          />
          <label style={{ fontFamily: 'Lexend', color: getColorDiff(diffStatus, iataCodeErr) }}>{label?.value?.it}</label>
        </span>
      </div>
    </Flex>
  )
}
