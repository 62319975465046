import React from 'react'

import { Text, PieChartComponent } from '../../../Components'
import { formatEuro3Decimals } from '../../../Services/Functions'

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF0000', '#0000FF', '#00FF00', '#FF00FF', '#FFFF00', '#00FFFF']

export const PieTotalPricingType = ({ data }) => {
  return (
    <PieChartComponent
      legendProps={{ layout: 'vertical', align: 'left', verticalAlign: 'middle' }}
      legend data={data || []} dataKey='value'
      colors={colors} innerRadius='0%' outerRadius='100%' paddingAngle='0'
      tooltipProps={{
        content: ({ payload: [{ payload: { name = '', value } = {} } = {}] = [] }) => (
          <div>
            <Text value={name} />
            <Text value={`Totale: ${formatEuro3Decimals(value)}`} />
          </div>
        )
      }}
    />
  )
}
