import React from 'react'

import { Text, Card, Flex } from '../../Components'

export const HandledPercentageNora = ({ handledPercentage }) => {
  return (
    <Card style={{ width: '100%', height: '100%' }}>
      <Flex fw fh>
        <Text bold size={20} value='Gestite correttamente da Nora' />
        <Text color={handledPercentage?.perc ? '#1ba91b' : 'yellow'} size={handledPercentage?.perc ? 40 : 15} value={handledPercentage?.perc ? `${handledPercentage?.perc > 100 ? 100 : handledPercentage?.perc}%` : 'Nessuna conversazione nel periodo selezionato'} />
        {!!handledPercentage?.totalHandled && <Text value={`Conversazioni: ${handledPercentage?.totalHandled}/${handledPercentage.totalConversationsLength}`} />}
        <Text style={{ marginTop: 10 }} value={'* sono escluse le conversazioni inoltrate all\'operatore'} size={10} />
      </Flex>
    </Card>

  )
}
