import { useEffect, useState } from 'react'
import { useTransferField } from './Hook'
import { Flex } from '../../../../Components'
import { FloatInput } from './InputForm'
import { AirportInput } from './AirportInput'
import { StationInput } from './StationInput'

export const TransferField = (props) => {
  const { item: { type = '' } = {} } = props || {}
  if (type === 'airport') { return <AirportInput {...props} /> }
  if (type === 'station') return <StationInput {...props} />
  return <StandardInput {...props} />
}

const StandardInput = ({ state: [state, setState], item, count, readOnly, diffStatus }) => {
  const { location, type, label, id, options, notRequired = false, minDate, fullLine = false } = item
  const [data = '', setData] = useTransferField([state, setState], location, count, !notRequired)
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(!data && !notRequired)
  }, [data, notRequired])

  return (
    <Flex key={item.id} as js style={{ width: fullLine ? '100%' : '23%', margin: '5px 1%' }}>
      <FloatInput
        value={[data, setData]} id={count + id} label={label.it} type={type} options={options}
        error={error} readOnly={readOnly} minDate={minDate} diffStatus={diffStatus}
      />
    </Flex>
  )
}
