import { objectMap } from './Functions'
import { StoredCapsule, Capsule } from './CapsuleClasses'

/**
 * Restituisce un oggetto Date che rappresenta la data corrente con
 * l'orario impostato a mezzanotte.
 *
 * Questa funzione crea un nuovo oggetto Date per ottenere la data
 * attuale e modifica l'orario della data risultante a mezzanotte
 * (00:00:00.000), rimuovendo qualsiasi informazione temporale specifica
 * come ore, minuti, secondi e millisecondi.
 *
 * @author mranocchia
 * @returns {Date} La data corrente con l'orario impostato a mezzanotte.
 */
export const getCurrentDate = () => {
  // Ottieni la data corrente
  const currentDate = new Date()

  // Imposta l'orario a mezzanotte
  currentDate.setHours(0)
  currentDate.setMinutes(0)
  currentDate.setSeconds(0)
  currentDate.setMilliseconds(0)

  return currentDate
}

/**
 * Restituisce un oggetto Date che rappresenta la data del primo giorno
 * del mese precedente rispetto alla data corrente, con l'orario impostato
 * a mezzanotte.
 *
 * Questo metodo calcola il mese precedente sottraendo uno al mese
 * corrente e imposta l'orario della data risultante a mezzanotte
 * (00:00:00.000) per garantire che nessuna informazione temporale
 * venga mantenuta.
 *
 * @author mranocchia
 * @returns {Date} La data del primo giorno del mese precedente con l'orario a mezzanotte.
 */
export const getPreviousMonthDate = () => {
  const previousMonthDate = new Date()
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1) // Sottrai un mese

  // Imposta l'orario a mezzanotte
  previousMonthDate.setHours(0)
  previousMonthDate.setMinutes(0)
  previousMonthDate.setSeconds(0)
  previousMonthDate.setMilliseconds(0)

  return previousMonthDate
}

const sessionState = {
  selectedDateGigiList: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  selectedDateConversationList: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  selectedStartDateDashboard: getPreviousMonthDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }),
  selectedEndDateDashboard: getCurrentDate().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }) // prendiamo il mese precedente
}

const storedState = {
  darkMode: window?.matchMedia('(prefers-color-scheme: dark)')?.matches || false
}

export const state = {
  ...objectMap(sessionState, (key, value) => new Capsule(key, value)),
  ...objectMap(storedState, (key, value) => new StoredCapsule(key, value))
}
