import React from 'react'
import { Flex, PanelText } from '../../../Components'
import { Editor } from '../../../Components/Common/Editor'
import { Divider } from 'primereact/divider'

export const Editors = ({ checkMeteo, quotesMessage: [quotesMessage, setQuotesMessage], selectedSteps, initialMessages }) => (
  <Flex js as wrap style={{ gap: 20 }} fw>
    <Divider />

    {selectedSteps === '2' &&
      <>
        <Flex js as>
          <PanelText value='Messaggio per preventivi:' header />
          <PanelText value={'All\'interno del messaggio dove compare:'} />
          <PanelText value={'- \'#link\': verrà inserito il link per visualizzare i preventivi'} />
          {checkMeteo && <PanelText value={'- \'#meteo\': verranno inserite le informazioni sul meteo (se richiesta viaggio è entro 5 gg dalla partenza). Per far visualizzare la frase è necessario abilitare il flag "Inclusione informazioni meteo"'} />}
        </Flex>
        <Editor
          id='quotesMessage'
          onChange={({ quotesMessage: changedQuotesMessage }) =>
            changedQuotesMessage.trim() !== quotesMessage.trim() && setQuotesMessage(changedQuotesMessage)}
          initialValue={initialMessages.quotesMessage || ''}
        />
      </>}
  </Flex>
)
